import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { memo } from 'react'

import { FCC } from '@/shared/lib'

const manifestUrl = new URL('tonconnect-manifest.json', window.location.href).toString()

export const TonProvider: FCC = memo(({ children }) => {

  return (
    <TonConnectUIProvider restoreConnection manifestUrl={manifestUrl}>
      {children}
    </TonConnectUIProvider>
  )
})
